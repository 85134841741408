import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import _ from "lodash"
import nl2br from "react-nl2br"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class PvCosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      chartType: props.type
    }
                        
    this.filePath = (props.type === 'equipment') ? `/csv/equipment-costs/equipment-costs-` : `/csv/power-generation-costs/power-generation-costs-`// CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartType][this.state.chartData][0].title
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))

    // CSVファイルからデータの読み込み
    //let fields = chartConfig.series[this.state.chartData].map((item) => { return item.name})
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: ['value']
      });
      processor.processMany(data);

      const colors = {
        'IEA': '#A5A5A5',
        'IRENA': '#ED7D31',
        '資源エネルギー庁': '#4472C4'
      }
        
      // グルーピング用にCSVデータを加工
      let tempData = []
      data.forEach((obj, id) => {
        tempData[id] = {}
        for (let k in obj) {
          //tempData[id]['category'] = obj[realName]
          tempData[id]['color'] = colors[obj['category']]
          tempData[id]['category'] = `${obj['category']} ${obj['provider']}`
          tempData[id][k] = obj[k]
        }
      })
      let byName = _.groupBy(tempData, xLabel)
      // コレクションの最長の個数取得
      let countItem = 0
      Object.keys(byName).forEach((key)=> {
        countItem = (countItem > byName[key].length) ? countItem : byName[key].length
      })
      let newData = []
      for (let i =0; i < countItem; i++) {
        newData[i] = []
        Object.keys(byName).forEach((key, id)=> {
          if (typeof byName[key][i] !== 'undefined') {
            newData[i].push(byName[key][i])
          }
        })
      }
      return newData
    })
    
    // X座標
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: xLabel,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30
        })
        //tooltip: am5.Tooltip.new(root, {})
      })
    );
    xAxis.data.setAll(data[0])
    let xRenderer = xAxis.get('renderer')
    xRenderer.labels.template.setAll({
      text: "",
      fontSize: "11px"
    })

    // Y座標(左)
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: chartConfig.axis[this.state.chartType][this.state.chartData][1].min,
      max: chartConfig.axis[this.state.chartType][this.state.chartData][1].max,
      numberFormat: chartConfig.axis[this.state.chartType][this.state.chartData][1].numberFormat,
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartType][this.state.chartData][1].range
    })
    yAxis.createAxisRange(rangeDataItem)

    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })
    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartType][this.state.chartData][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))

    this.xAxis = xAxis
    this.yAxis = yAxis
    this.chart = chart

    // Legendの指定
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(0),
      centerX: am5.percent(0),
      width: am5.percent(100),
      layout: root.gridLayout,
      clickTarget: "none"
    }))

    legend.labels.template.setAll({
      fontSize: 11
    })
    legend.markers.template.setAll({
      width: 14,
      height: 14
    })

    chartConfig.series[this.state.chartData].forEach(item => {
      this.createSeries(data, legend, item.name, item.fill)
    })  
    data.map((item) => {
      return this.createData(item, chartConfig.series[this.state.chartData])
    })

    // Providerでグルーピング
    this.createProvider(data)

  }

  // Providerの指定
  createProvider(data) {
    const flatten = _.flatten(data)
    const sorted = _.sortBy(flatten, ['provider', 'category'])
    const byName = _.groupBy(sorted, 'provider')

    let xAxis = this.xAxis
    
    Object.keys(byName).forEach((key) => {
      let range = xAxis.makeDataItem({})
      xAxis.createAxisRange(range)
      
      range.set("category", byName[key][0].category)
      range.set("endCategory", byName[key][byName[key].length - 1].category)
      
      let label = range.get("label")
      label.setAll({
        text: byName[key][0].provider,
        dy: 0
      })
      
      let tick = range.get("tick");
      tick.setAll({ visible: true, strokeOpacity: 0.3, length: 20, location: 0 });
    
      //let grid = range.get("grid");
      //grid.setAll({ strokeOpacity: 1 });
    })
  }

  createSeries(data, legend, name, fill) {
    // シリーズの設定
    let root = this.root
    let chart = this.chart
    let xAxis = this.xAxis
    let yAxis = this.yAxis
    let categoryLabel = this.getConfig().axis[this.state.chartType][this.state.chartData][0].title

    // 折線グラフの設定
    var series = chart.series.push(am5xy.LineSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: categoryLabel,
      stroke: "rgba(0,0,0,0)",
      maskBullets:false
    }));
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Circle.new(root, {
          //tooltipText: `[font-size:12px]{categoryX}: {valueY}`,
          radius: 3,
          fill: fill,
          stroke: fill
        })
      })
    })
    series.data.setAll(data)
    legend.data.setAll(chart.series.values);
  }

  createData(data, seriesSetting) {
    // シリーズの設定
    let root = this.root
    let chart = this.chart
    let xAxis = this.xAxis
    let yAxis = this.yAxis
    let categoryLabel = this.getConfig().axis[this.state.chartType][this.state.chartData][0].title

    // 折線グラフの設定
    var series = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: categoryLabel,
      stroke: "rgba(0,0,0,0)",
      maskBullets:false
    }));
    let i = -1
    series.bullets.push(function() {
      i++
      let container = am5.Container.new(root, {
        centerX: am5.p50,
        centerY: am5.p50
      });
      container.children.push(
        am5.Circle.new(root, {
          radius: 3,
          fill: data[i].color,
          stoke: data[i].color
        })
      );
      return am5.Bullet.new(root, {
        sprite: container
      })
    })
    series.data.setAll(data)
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query pvCostsQuery {
            settings: allPvCostsJson {
              edges {
                node {
                  series {
                    pvHome {
                      label
                      type
                      name
                      fill  
                    }
                    pvMegaSolar {
                      label
                      type
                      name
                      fill  
                    }
                  }
                  axis {
                    equipment {
                      pvHome {
                        label
                        max
                        min
                        range
                        realName
                        title
                        type
                      }
                      pvMegaSolar {
                        label
                        max
                        min
                        range
                        realName
                        title
                        type
                      }
                    }
                    powerGeneration {
                      pvHome {
                        label
                        max
                        min
                        range
                        realName
                        title
                        type
                      }
                      pvMegaSolar {
                        label
                        max
                        min
                        range
                        realName
                        title
                        type
                      }
                    }
                  }
                  head {
                    equipment {
                      pvHome
                      pvMegaSolar  
                    }
                    powerGeneration {
                      pvHome
                      pvMegaSolar  
                    }
                  }
                  title {
                    pvHome
                    pvMegaSolar
                  }
                  source {
                    equipment {
                      pvHome {
                        explain
                        sourceText
                      }
                      pvMegaSolar {
                        explain
                        sourceText
                      }  
                    }
                    powerGeneration {
                      pvHome {
                        explain
                        sourceText
                      }
                      pvMegaSolar {
                        explain
                        sourceText
                      }  
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            { (data.settings.edges[0].node.head[this.state.chartType][this.state.chartData]) ? 
                <div className={styles.chartTitle}>
                  <h2>{ data.settings.edges[0].node.head[this.state.chartType][this.state.chartData] }</h2>
                </div> : ''
            }
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              { (data.settings.edges[0].node.source[this.state.chartType][this.state.chartData].sourceLink === '') ? '' :
                <p className={styles.chartSource} dangerouslySetInnerHTML={{ __html: data.settings.edges[0].node.source[this.state.chartType][this.state.chartData].sourceText}} />
              }
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartType][this.state.chartData].explain)}</p>
              {/*<p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>*/}
            </div>
          </>
        )}
      />
    )
  }
}
export default PvCosts
