import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

import SearchFilter from "../../components/parts/chart/search-filter"
import PvCosts from "../../components/parts/chart/pv-costs"
import EquipmentCostsFuture from "../../components/parts/chart/equipment-costs-future"
import PowerGenerationCostsFuture from "../../components/parts/chart/power-generation-costs-future"
import MarketShare from "../../components/parts/chart/market-share"
import Patent from "../../components/parts/chart/patent"
import BubbleChart from "../../components/parts/chart/bubble-chart"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.searchInit = {
      filter1Init: 0,
      filter2Init: 1,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <PvCosts id="equipmentCostHome" type="equipment" chartData="pvHome" sourceHide="true" width="100%" height="420px" />
              <PvCosts id="equipmentCostMegaSolar" type="equipment" chartData="pvMegaSolar" titleHide="true" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PvCosts id="powerGenerationCostsFutureHome" type="powerGeneration" chartData="pvHome" sourceHide="true" width="100%" height="420px" />
              <PvCosts id="powerGenerationCostsFutureMegaSolar" type="powerGeneration" chartData="pvMegaSolar" titleHide="true" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <EquipmentCostsFuture id="equipmentCostsFuture" chartData="pv" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <PowerGenerationCostsFuture id="powerGenerationCostsFuture" chartData="pv" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>モジュール変換効率</h2>
              </div>
              <a href="https://www.nrel.gov/pv/module-efficiency.html" target="_blank" rel="noreferrer noopener">
                <StaticImage src={'../../images/chart/pv-module.png'} alt="" placeholder="blurred" width={559} />
              </a>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.nrel.gov/pv/module-efficiency.html" target="_blank" rel="noreferrer noopener">米国国立再生可能エネルギー研究所（NREL）</a>（2021年10月時点）</p>
                <p className={chartStyles.chartExplain}>第三者の計測機関により、IEC 60904-3 Ed.2又はASTM G173に基づき測定されたモジュール種類別の最高効率を1988年以降、時系列で表示<br />
This plot is courtesy of the National Renewable Energy Laboratory, Golden, CO.</p>
              </div>

            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>セル変換効率</h2>
              </div>
              <div className="pb-1">
                <a href="https://www.nrel.gov/pv/cell-efficiency.html" target="_blank" rel="noreferrer noopener">
                  <StaticImage src={'../../images/chart/pv-cell.png'} alt="" placeholder="blurred" width={559} />
                </a>
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.nrel.gov/pv/cell-efficiency.html" target="_blank" rel="noreferrer noopener">米国国立再生可能エネルギー研究所（NREL）</a>（2021年10月時点）</p>
                <p className={chartStyles.chartExplain}>第三者の計測機関により、IEC 60904-3 Ed.2又はASTM G173に基づき測定されたセル種類別の最高効率を1976年以降、時系列で表示<br />
This plot is courtesy of the National Renewable Energy Laboratory, Golden, CO.</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="pvCompetitiveness" chartData="pvCompetitiveness" defaultItem="crystalline" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="pvElementTech" chartData="pvElementTech" defaultItem="10More" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <MarketShare id="marketShareModule" chartData="Module" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <MarketShare id="marketShareCell" chartData="Cell" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <Patent id="patent" chartData="pv" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>          

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`